.course-nav li {
  display: inline-block;
}
.course-nav li + li {
  margin-left: 0.6em;
}
.course-nav a {
  font-size: 0.85em;
  color: black;
  padding: 0.2em 0.8em;
  border-radius: 0.25em;
  background: lightgray;
  transition: 0.2s;
}
.course-nav a:hover,
.course-nav a.active {
  color: #fff;
  background: rgba(3, 70, 148, 1);
}
.course-nav {
  width: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.long-bottom {
  margin-bottom: 3em;
}

@media screen and (max-width: 760px) {
  .floater {
    width: 22em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 761px) and (max-width: 1139px) {
  .floater {
    width: 44em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1140px) {
  .floater {
    width: 65em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
