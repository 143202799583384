.player-block {
  display: block;
  overflow: hidden;
}
.preserve-ratio {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 51%;
}
.is-invisible {
  display: none;
}

iframe {
  width: 100%;
}
.preserve-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
button {
  font-size: 0.85em;
  color: black;
  padding: 0.3em 0.8em;
  border-radius: 0.25em;
  background: lightgray;
  transition: 0.2s;
  float: right;
  border-style: none;
}
button:hover,
button.active {
  color: #fff;
  background: rgba(3, 70, 148, 1);
}
