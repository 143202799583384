.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  fill:#000000;
  fill-opacity:1;
  }

.centered h1 {
  text-align: center;
  display:block;
}

.centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
