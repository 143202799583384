ul {
  list-style-type: none;
  padding-left: 0;
}
a {
  text-decoration: none;
  color: black;
}

body {
  min-width: 375px;

  color: black;
}

h1,
h2 {
  text-transform: uppercase;
  display: inline-block;
}

.bodyContainer {
  width: 90%;
  max-width: 1100px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
}
p {
  text-align: left;

  width: auto;

  line-height: 23px;
}
p a {
  text-decoration: underline;
}

.main-img-box {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 4px;
}
.img-container {
  max-height: inherit;
  transform: translateY(40%);
}
.main-img-box img {
  height: auto;
  width: 100%;
  display: block;
  transform: translateY(-40%);
}

.accessible-reading {
  max-width: 795px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 745px) {
  .about-pic-upper {
    float: right;
    width: 400px;
    height: auto;
    margin: 20px 0 20px 30px;
    border-radius: 4px;
  }
  .about-pic-bottom {
    float: right;
    width: 400px;
    height: auto;
    margin: 20px 0 20px 30px;
    border-radius: 4px;
  }
  p {
    font-size: 18px;
  }
}
@media screen and (max-width: 744px) {
  .about-pic-upper {
    display: block;
    width: 97%;
    height: auto;
    border-radius: 4px;
    padding: 30px 10px 10px 6px;
  }
  .about-pic-bottom {
    display: block;
    width: 97%;
    height: auto;
    padding: 30px 10px 10px 6px;
    border-radius: 4px;
  }
}

hr {
  display: block;
  width: inherit;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  border-color: black;
}
