.content-container {
  width: 20em;
  background-color: rgba(3, 70, 148, 1);
  overflow: hidden;
  padding: 1px;
  padding-bottom: 15px;
  margin: 10px;
  display: block;
  border-radius: 3px;
}
.content-pic-container {
  height: 13em;
  overflow: hidden;
  width: inherit;
  display: block;
}
.content-container img {
  height: auto;
  width: 100%;
}
.content-container h3 {
  width: inherit;
  display: block;
  color: white;
  margin-left: 0.5em;
  margin-bottom: 0.2em;
}

.content-post {
  float: left;
}
.content-container a {
  color: white;
}

.blog-container {
  width: auto;
  background-color: rgba(3, 70, 148, 1);
  overflow: hidden;
  padding: 1px;
  margin: 30px 10px;
  display: block;
  border-radius: 3px;
  color: white;
}

.blog-container h2 {
  color: white;
  margin: 0.7em;
  margin-bottom: 0.25em;
}

.blog-container hr {
  border-color: white;
}

.sub-box-top p {
  margin: 0.2em 1em 1em 1em;
}
.blog-container a {
  color: white;
}

@media screen and (min-width: 737px) {
  .pic-box {
    width: 35%;
    height: 300px;
    overflow: hidden;
    display: inline-block;
    margin: 10px 0 10px 10px;
  }
  .sub-box {
    display: inline-block;
    height: 320px;
    width: 62%;
    float: right;
  }

  .sub-box-top {
    height: 87%;
    overflow: hidden;
  }
  .blog-pic {
    float: left;
    width: auto;
    height: 300px;
    border-radius: 3px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    margin: 0.5em;
  }
}
@media screen and (max-width: 737px) {
  .pic-box {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
  }

  .blog-pic {
    width: 95%;
    height: auto;
    border-radius: 3px;
    position: relative;
    left: 50%;
    transform: translate(-52%);
    background-color: black;
    margin: 0.5em;
  }
  .sub-box {
    display: block;
  }
}

.tag-nav li {
  display: inline-block;
  margin: 0 0.3em 0.6em 0.3em;
}
.tag-nav ul,
h4 {
  color: white;
  display: inline-block;
  float: left;
  margin: 0 0 0 0.3em;
}
.tag-nav a {
  font-size: 0.85em;
  padding: 0.2em 0.8em;
  border-radius: 0.25em;
  background: lightblue;
  transition: 0.2s;
  color: black;
}

.tag-nav {
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  margin-left: 0.7em;
  margin-top: 0.3em;
}
