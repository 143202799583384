@import url(https://fonts.googleapis.com/css?family=Lato|Source+Sans+Pro&display=swap);
.header {
  display: block;
  position: relative;
  z-index: 4;
  background-color: rgba(3, 70, 148, 1);
  width: 100%;
  padding: 0;
}

.icnh {
  font-size: 20px;
  position: relative;
  margin-right: 0.1em;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.a {
  stroke: #fff;
  fill: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.4px;
}

.header ul {
  margin: 0 6px 0 0;
  float: right;
}
.header h2 {
  display: inline-block;
  float: left;
  color: white;
  margin: 0;
  padding: 0.28em;
}
.h-wrapper {
  max-width: 1150px;
  margin: auto;
  width: 97%;
}
.main-nav a {
  font-size: 0.95em;
  color: white;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  padding: 0.78em 0.63em 0.78em 0.63em;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  margin-left: 0.25em;
}

.main-nav a:hover,
.main-nav .active {
  color: black;
  background: white;
}

.main-nav li {
  display: inline-block;
}
@media screen and (min-width: 738px) {
  .top-bar {
    display: inline-block;
  }
  .hamburger {
    display: none;
  }
  .nav-wrapper {
    display: inline-block;
    float: right;
  }
}
@media screen and (max-width: 737px) {
  .nav-is-invisible {
    display: none;
  }

  .main-nav li {
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0px;
  }
  .hamburger {
    float: right;
    margin: 0.5em;
    color: white;
  }
  .top-bar {
    display: block;
    height: 45px;
    width: 100%;
  }
  .header h2 {
    position: relative;
    margin: auto;
    left: 50%;
    text-align: center;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .main-nav a {
    display: block;
    float: none;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
            transform: none;
    margin: 0px;
  }

  .header ul {
    width: 100%;
    float: none;
    margin: 0px;
    display: block;
    background-color: darkgrey;
  }

  .header div {
    width: 100%;
    margin: 0;
  }
}

.homebody {
  min-height: 375px;
  min-width: 375px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
}
.a {
  fill: rgba(255, 255, 255, 0.6);
}
.b {
  fill: #c5c7c7;
}
.d {
  fill: #034694;
}
.e {
  fill: #fff;
}
.f {
  fill: #ed1c24;
}
.g {
  fill: none;
}

.overlay {
  width: 55vh;
  position: absolute;
  left: 30%;
  top: 25vh;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2;
}
.overlay2 {
  width: 45vh;
  position: absolute;
  left: 30%;
  top: 30vh;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 3;
}
.home-image {
  height: 110vh;
  width: auto;
  position: absolute;
  left: 50%;
  top: -5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}

@media screen and (max-aspect-ratio: 1/1) {
  .overlay {
    width: 30vh;
    position: absolute;
    left: 30%;
    top: 47.5vh;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
  }
  .overlay2 {
    width: 25vh;
    position: absolute;
    left: 30%;
    top: 50vh;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 3;
  }
  .home-image {
    height: 110vh;
    width: auto;
    position: absolute;
    left: 30%;
    top: -5%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1;
  }
  .g {
    fill: rgba(255, 255, 255, 1);
  }
}

@media screen and (max-aspect-ratio: 370/600) {
  .overlay {
    width: 30vh;
    position: absolute;
    left: 30%;
    top: 37.5vh;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
  }
  .overlay2 {
    width: 25vh;
    position: absolute;
    left: 30%;
    top: 40vh;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 3;
  }
  .home-image {
    height: 90vh;
    width: auto;
    position: absolute;
    left: 30%;
    top: -5%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1;
  }
}
.spin {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 100000ms;
          animation-duration: 100000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  fill: #000000;
  fill-opacity: 1;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 5000ms;
          animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  fill:#000000;
  fill-opacity:1;
  }

.centered h1 {
  text-align: center;
  display:block;
}

.centered{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

@-webkit-keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

.footer {
  display: block;
  padding: 20px;
  width: 100%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.icnf {
  font-size: 14px;
  position: relative;
}
.icns {
  font-size: 30px;
  position: relative;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  margin: 0 1em;
}

.f-wrapper {
  max-width: 1150px;
  width: 97%;
  display: block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 0;
}

.f-wrapper div {
  width: 100%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0;
  margin-right: 0;
}
.f-wrapper p {
  width: 100%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0;
  margin-right: 0;
}

.content-container {
  width: 20em;
  background-color: rgba(3, 70, 148, 1);
  overflow: hidden;
  padding: 1px;
  padding-bottom: 15px;
  margin: 10px;
  display: block;
  border-radius: 3px;
}
.content-pic-container {
  height: 13em;
  overflow: hidden;
  width: inherit;
  display: block;
}
.content-container img {
  height: auto;
  width: 100%;
}
.content-container h3 {
  width: inherit;
  display: block;
  color: white;
  margin-left: 0.5em;
  margin-bottom: 0.2em;
}

.content-post {
  float: left;
}
.content-container a {
  color: white;
}

.blog-container {
  width: auto;
  background-color: rgba(3, 70, 148, 1);
  overflow: hidden;
  padding: 1px;
  margin: 30px 10px;
  display: block;
  border-radius: 3px;
  color: white;
}

.blog-container h2 {
  color: white;
  margin: 0.7em;
  margin-bottom: 0.25em;
}

.blog-container hr {
  border-color: white;
}

.sub-box-top p {
  margin: 0.2em 1em 1em 1em;
}
.blog-container a {
  color: white;
}

@media screen and (min-width: 737px) {
  .pic-box {
    width: 35%;
    height: 300px;
    overflow: hidden;
    display: inline-block;
    margin: 10px 0 10px 10px;
  }
  .sub-box {
    display: inline-block;
    height: 320px;
    width: 62%;
    float: right;
  }

  .sub-box-top {
    height: 87%;
    overflow: hidden;
  }
  .blog-pic {
    float: left;
    width: auto;
    height: 300px;
    border-radius: 3px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: black;
    margin: 0.5em;
  }
}
@media screen and (max-width: 737px) {
  .pic-box {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
  }

  .blog-pic {
    width: 95%;
    height: auto;
    border-radius: 3px;
    position: relative;
    left: 50%;
    -webkit-transform: translate(-52%);
            transform: translate(-52%);
    background-color: black;
    margin: 0.5em;
  }
  .sub-box {
    display: block;
  }
}

.tag-nav li {
  display: inline-block;
  margin: 0 0.3em 0.6em 0.3em;
}
.tag-nav ul,
h4 {
  color: white;
  display: inline-block;
  float: left;
  margin: 0 0 0 0.3em;
}
.tag-nav a {
  font-size: 0.85em;
  padding: 0.2em 0.8em;
  border-radius: 0.25em;
  background: lightblue;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: black;
}

.tag-nav {
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  margin-left: 0.7em;
  margin-top: 0.3em;
}

.course-nav li {
  display: inline-block;
}
.course-nav li + li {
  margin-left: 0.6em;
}
.course-nav a {
  font-size: 0.85em;
  color: black;
  padding: 0.2em 0.8em;
  border-radius: 0.25em;
  background: lightgray;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.course-nav a:hover,
.course-nav a.active {
  color: #fff;
  background: rgba(3, 70, 148, 1);
}
.course-nav {
  width: 250px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
}

.long-bottom {
  margin-bottom: 3em;
}

@media screen and (max-width: 760px) {
  .floater {
    width: 22em;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media screen and (min-width: 761px) and (max-width: 1139px) {
  .floater {
    width: 44em;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media screen and (min-width: 1140px) {
  .floater {
    width: 65em;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.player-block {
  display: block;
  overflow: hidden;
}
.preserve-ratio {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 51%;
}
.is-invisible {
  display: none;
}

iframe {
  width: 100%;
}
.preserve-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
button {
  font-size: 0.85em;
  color: black;
  padding: 0.3em 0.8em;
  border-radius: 0.25em;
  background: lightgray;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  float: right;
  border-style: none;
}
button:hover,
button.active {
  color: #fff;
  background: rgba(3, 70, 148, 1);
}

.label-form {
  width: 85%;
  float: left;

  font-size: 1.2em;
}

.input-form {
  width: 95%;
  height: 2em;
  float: left;
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: lightgray;
}
.text-form {
  width: 99.3%;
  float: left;
  height: 17em;
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: lightgray;
  margin-top: 20px;
  margin-bottom: 10px;
}
.unit {
  float: left;

  width: 50%;
}
.unit-bottom {
  float: left;
  padding-bottom: 0.4em;
  width: 97.6%;
}

input {
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: lightgray;
  width: 47.5%;
  float: left;
  padding: 5px 0;
  margin: 20px 0;
}

.first {
  margin-right: 5%;
}

form {
  margin-left: 0.8em;
}

.paraboveform {
  padding: 0 2%;
  margin-bottom: 3em;
}

ul {
  list-style-type: none;
  padding-left: 0;
}
a {
  text-decoration: none;
  color: black;
}

body {
  min-width: 375px;

  color: black;
}

h1,
h2 {
  text-transform: uppercase;
  display: inline-block;
}

.bodyContainer {
  width: 90%;
  max-width: 1100px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
}
p {
  text-align: left;

  width: auto;

  line-height: 23px;
}
p a {
  text-decoration: underline;
}

.main-img-box {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 4px;
}
.img-container {
  max-height: inherit;
  -webkit-transform: translateY(40%);
          transform: translateY(40%);
}
.main-img-box img {
  height: auto;
  width: 100%;
  display: block;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
}

.accessible-reading {
  max-width: 795px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media screen and (min-width: 745px) {
  .about-pic-upper {
    float: right;
    width: 400px;
    height: auto;
    margin: 20px 0 20px 30px;
    border-radius: 4px;
  }
  .about-pic-bottom {
    float: right;
    width: 400px;
    height: auto;
    margin: 20px 0 20px 30px;
    border-radius: 4px;
  }
  p {
    font-size: 18px;
  }
}
@media screen and (max-width: 744px) {
  .about-pic-upper {
    display: block;
    width: 97%;
    height: auto;
    border-radius: 4px;
    padding: 30px 10px 10px 6px;
  }
  .about-pic-bottom {
    display: block;
    width: 97%;
    height: auto;
    padding: 30px 10px 10px 6px;
    border-radius: 4px;
  }
}

hr {
  display: block;
  width: inherit;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  border-color: black;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-family-fixed: "Courier New", monospace;
  font-weight: 300;
  letter-spacing: 0.11rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(213, 222, 232, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

