.label-form {
  width: 85%;
  float: left;

  font-size: 1.2em;
}

.input-form {
  width: 95%;
  height: 2em;
  float: left;
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: lightgray;
}
.text-form {
  width: 99.3%;
  float: left;
  height: 17em;
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: lightgray;
  margin-top: 20px;
  margin-bottom: 10px;
}
.unit {
  float: left;

  width: 50%;
}
.unit-bottom {
  float: left;
  padding-bottom: 0.4em;
  width: 97.6%;
}

input {
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: lightgray;
  width: 47.5%;
  float: left;
  padding: 5px 0;
  margin: 20px 0;
}

.first {
  margin-right: 5%;
}

form {
  margin-left: 0.8em;
}

.paraboveform {
  padding: 0 2%;
  margin-bottom: 3em;
}
